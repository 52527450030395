<template>
  <!--  估价渠道-->
  <div class="box">
    <GlobalInfoBar title="估价单" content="管理查找快速估价的记录" />
    <!-- 查询 -->
    <GlobalChunk icon="search" title="筛选查询">
      <!-- 筛选 -->
      <template v-slot:filter>
        <GlobalForm :form-item-list="formItemList"  :needBtnLoading="true" round size="mini" :inline="true" :init-data="initData"
          @handleChange="handleChange" @handleConfirm="handleConfirm" confirm-btn-name="查询" />
      </template>
      <template v-slot>
        <div class="header-box">
          <div class="header-icon">
            <img src="@/assets/images/icon_lb@2x.png" alt="">
            <h2>订单列表</h2>
            <span class="header-num">（{{ tableDatas.allCount }}）</span>
          </div>
          <el-button :loading="isExecl" size="mini" @click="exportExcel" icon="el-icon-download" type="success">导出Excel</el-button>
        </div>
        <div class="tabble-inner">
          <el-table v-bind="tableStyle" v-loading="tableDatas.loading" :data="tableDatas.data">
            <el-table-column v-for="item in tableDatas.tableColumns" :key="item.prop" :prop="item.prop" align="center"
              :label="item.label" :width="item.width">
              <template v-slot="{ row }">
                <!-- 关联回收单号 -->
                <div v-if="item.prop === 'recycleOrderNo'" @click="sendToDetail(row[item.prop])">
                  <p :class="[row[item.prop] !== '--' ? 'lv_fc_line' : '']">{{ row[item.prop] }}</p>
                </div>
                <!-- 显示预估价 -->
                <div v-else-if="item.prop === 'showPrice'">
                  <p>{{ row[item.prop] }}</p>
                  <p>{{ row['sideShowPrice'] }}</p>
                  <p v-if="row[item.prop] !== '--'" class="lv_fc_line" @click="detailPriceClick(row)">查看明細</p>
                </div>
                <!-- 验机报告 -->
                <div v-else-if="item.prop === 'report'" @click="testClick(row)">
                  <p class="lv_fc_line">查看</p>
                </div>
                <!-- 估价渠道 -->
                <div v-else-if="item.prop === 'channel'">
                  <p v-if="row[item.prop] === 'fh'">分毫</p>
                  <p v-else-if="row[item.prop] === 'chx'">采货侠</p>
                  <p v-else>--</p>
                </div>
                <!-- 订单状态 -->
                <div v-else-if="item.prop === 'recycleOrderState'">
                  <span v-if="row[item.prop] === '00'">待确认</span>
                  <span v-else-if="row[item.prop] === '01'">已确认</span>
                  <span v-else-if="row[item.prop] === '02'">待支付</span>
                  <span v-else-if="row[item.prop] === '03'">已绑码</span>
                  <span v-else-if="row[item.prop] === '04'">已收货</span>
                  <span v-else-if="row[item.prop] === '05'">降价收货</span>
                  <span v-else-if="row[item.prop] === '10'">已取消</span>
                  <span v-else-if="row[item.prop] === '20'">已作废</span>
                  <span v-else-if="row[item.prop] === '30'">已退回</span>
                </div>
                <div v-else>
                  {{ row[item.prop] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :meta="meta"
            @sizeChange="(v) => { meta = { ...meta, pageSize: v }; params = { ...params, pageSize: v } }"
            @pageChange="(v) => { meta = { ...meta, pageNum: v }; params = { ...params, pageNum: v } }" />
        </div>
      </template>
    </GlobalChunk>

    <!-- 初步验机报告 -->
    <el-dialog v-bind="dialogProps" :before-close="() => dialogProps.show = false" :visible.sync="dialogProps.show">
      <div class="test-box">
        <div class="test-info">
          <Title title="旧机基本情况" />
          <TestDeviceInfo :datainfo="{
            machineName: dialogProps.report.machineName,
            canStartingUp: dialogProps.report.canStartingUp,
            isNew: dialogProps.report.isNew,
            isElderlyMachine: dialogProps.report.isElderlyMachine,
            informationList: dialogProps.report.sku || []
          }" />
        </div>
        <div class="test-result">
          <Title title="人工验机项" />
          <TestTable :table-data="dialogProps.report.funcData || []"></TestTable>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="dialogProps.show = false" plain type="primary">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 初步验机报告 -->
    <el-dialog v-bind="detailDialogPorps" :before-close="() => detailDialogPorps.show = false"
      :visible.sync="detailDialogPorps.show">
      <div class="detail-price-box">
        <div class="detail-price-line1">
          <p>原始预估价：{{ tableDatas.currentData.channelPrice }}</p>
          <p>商户预估价：{{ tableDatas.currentData.factorPrice }}</p>
        </div>
        <div class="lv_fc detail-price-line2">
          店员查看预估价：{{ tableDatas.currentData.viewPrice }} ({{ tableDatas.currentData.sideShowPrice }})
        </div>
        <div class="detail-price-line3">
          <p>扣除利润：{{ tableDatas.currentData.allCompanyRebate }}元；（门店返利{{
            tableDatas.currentData.storeRebate
          }}元；预留返利{{ tableDatas.currentData.delayRebate }}元）</p>
          <p>扣除红包：{{ tableDatas.currentData.allStaffReward }}元 （店长{{
            tableDatas.currentData.smStaffReward
          }}元；店员{{ tableDatas.currentData.clStaffReward }}元）</p>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="detailDialogPorps.show = false" plain type="primary">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { formItemList, tableDatas, dialogProps, detailDialogPorps } from "@/views/RecallOrder/initState";
import GlobalChunk from "@/components/global/components/GlobalChunk.vue";
import GlobalInfoBar from "@/components/global/components/GlobalInfoBar.vue";
import moment from "moment/moment";
import _api from "@/utils/request";
import {
  computedMoney, convertYypeNumer,
  getHaoTime,
  getHealthCode,
  getIndex,
  getMeta,
  guideExcelAction, infoConcat,
  pageSize,
  setValue,
  tableStyle
} from "@/utils/common";
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import Title from "@/views/RecallOrder/componets/Title.vue";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import Pagination from "@/components/common/Pagination.vue";
import GlobalForm from "@/components/global/components/GlobalForm.vue";

export default {
  computed: {
    tableStyle() {
      return tableStyle
    }
  },
  components: { GlobalForm, Pagination, TestDeviceInfo, Title, TestTable, GlobalInfoBar, GlobalChunk },
  data() {
    const startTime = moment().subtract(0, 'day').startOf('day').format("YYYY-MM-DD");
    const endTime = moment().endOf('day').format("YYYY-MM-DD");
    const time = [startTime, endTime];
    const { id, companyId, companyName } = this.$route.query;
    return {
      formItemList,
      initData: {
        time,
        healthCode: null,
        isBuildRecycle: null,
        channelAssessNo: id ?? undefined,
        companyId: companyName
      },
      // 导表loading
      isExecl: false,
      // 表格数据
      tableDatas,
      // 研究弹窗
      dialogProps,
      // 预估明细弹窗
      detailDialogPorps,
      // 参数
      params: {
        time,
        pageSize,
        pageNum: 1,
        channelAssessNo: id ?? undefined,
        companyId
      },
      // 分页数据
      meta: {
        total: 0,
        pageSize,
        pageNum: 1,
      },
    }
  },
  created() {
    this.getSelect();
    this.getData();
  },
  mounted() {
    this.$nextTick(() => {
      const { companyId } = this.$route.query;
      if (companyId) {
        const index = this.formItemList.findIndex(v => v.key === 'storeId');
        this.formItemList[index].disabled = false;
        this.getShopList(companyId);
      }
    })
  },
  watch: {
    params(v) {
      this.getData();
    }
  },
  methods: {
    //详情
    sendToDetail(recycleOrderNo) {
      console.log(recycleOrderNo);
      if (recycleOrderNo == '--') {
        return false
      }
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: recycleOrderNo },
      });
      window.open(routeData.href, "_blank");
    },
    // 设置时间
    setParamsTIme() {
      let params = this.params;
      params = {
        ...params,
        startTime: getHaoTime(params.time[0], 'start'),
        endTime: getHaoTime(params.time[1], 'end'),
        time: undefined,
      };
      return params;
    },
    // list rq
    getData() {
      let params = this.params;
      if (!params.time) {
        if (typeof this.params.disuseLoding == "function") {
          this.params.disuseLoding();
        }
        return this.$message.error("请输入下单时间范围")
      }
      const { companyId, companyName } = this.$route.query;
      this.tableDatas.loading = true;
      params = this.setParamsTIme(params);
      params = {
        ...params,
        companyId: params.companyId === companyName ? companyId : params.companyId
      }
      _api.chxassessList(params).then(r => {
        this.tableDatas.loading = false;
        const data = r.data ?? { allCount: 0, ipage: { orders: [], records: [], total: 0 } };
        this.tableDatas.allCount = r.data.allCount;
        // 分页
        this.meta = getMeta(r.data.ipage.total, params.pageNum, params.pageSize);
        this.tableDatas.data = r.data.ipage.records.map((v, i) => {
          v.sideShowPrice = infoConcat(v.leftSideShowPrice, v.rightSideShowPrice)
          v = setValue(v);
          // v.channelPrice
          // v.factorPrice
          return {
            ...v,
            index: getIndex(params.pageNum, params.pageSize, i),
            healthCode: getHealthCode(v.healthCode),
            viewPrice: computedMoney(v.factorPrice, computedMoney(v.allCompanyRebate, v.allStaffReward), false)
          }
        });
      }).finally(() => {
        if (typeof this.params.disuseLoding == "function") {
          this.params.disuseLoding();
        }
      })
    },
    // 查询操作
    handleConfirm(v,cd) {
      this.params = {
        ...this.params,
        ...v,
        pageNum: 1,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
    },
    // 搜索项变化
    handleChange(v1, v2) {
      if (v1.key === "companyId") {
        const index = this.formItemList.findIndex(v => v.key === v1.childrenKey);
        if (v2) {
          this.formItemList[index].disabled = false;
          this.getShopList(v2);
        } else {
          this.formItemList[index].disabled = true;
        }
      }
    },

    // 门店下拉
    getShopList(companyId) {
      _api.getStoreSelectList({ companyId }).then(r => {
        const data = r.data || [];
        const index = this.formItemList.findIndex(v => v.key === 'storeId');
        this.formItemList[index].option = data.map(v => {
          return {
            label: v.value,
            value: v.key
          }
        })
      })
    },
    // 下拉列
    getSelect() {
      const formItemList = this.formItemList;
      // 商户下拉
      _api.getSelectList().then(r => {
        const index = this.formItemList.findIndex(v => v.key === 'companyId');
        if (index >= 0) {
          this.formItemList[index].option = r.data.map(v => {
            return {
              label: v.name,
              value: v.id
            }
          })
        }
      });
      // 回收分类下拉
      _api.selectList().then(r => {
        const index = formItemList.findIndex(v => v.key === 'typeId');
        if (index >= 0) {
          this.formItemList[index].option = r.data.map(v => {
            return {
              label: v.name,
              value: v.id
            }
          })
        }
      });

      // 机况
      _api.getHealthList().then(r => {
        const index = formItemList.findIndex(v => v.key === 'healthCode');
        if (index >= 0) {
          this.formItemList[index].option = r.data.map(v => {
            return {
              label: v.key,
              value: v.value
            }
          })
        }
      })
    },
    // 导出表
    exportExcel() {
      let params = this.params;
      if (!params.time) {
        return this.$message.error("请输入下单时间范围")
      }
      console.log(this.params);
      this.isExecl = true;
      params = this.setParamsTIme(params);
      const { companyId, companyName } = this.$route.query;
      params.companyId = params.companyId === companyName ? companyId : params.companyId
      _api.chxassessExcel(params).then(r => {
        guideExcelAction(r, '估价单列表', () => this.isExecl = false);
      }).catch(() => this.isExecl = false)
    },
    // 查看明細
    detailPriceClick(v) {
      this.tableDatas.currentData = v;
      this.detailDialogPorps.show = true;
    },
    // 验机报告查看
    testClick(v) {
      _api.getReport(v.channelAssessNo).then(r => {
        this.dialogProps.show = true;
        this.dialogProps.report = r.data ?? { funcData: [], sku: [] };
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-icon {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  >img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    transform: translateY(1px);
  }

  >h2 {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
  }
}

.fc_line {
  color: #0981FF;
  text-decoration: underline;
  cursor: pointer;
}

.header-num {
  font-weight: 700;
  font-size: 18px;
  color: #FF5757;
}

.detail-price-box {
  font-size: 14px;
  color: #000;

  .detail-price-line1 {
    display: flex;
    align-items: center;

    >p:first-of-type {
      margin-right: 30px;
    }
  }

  .detail-price-line2 {
    margin-top: 10px;
  }

  .detail-price-line3 {
    margin-top: 10px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background: #F2F2F2;
    border-radius: 6px;

    >p {
      margin-top: 10px;
    }
  }
}
</style>
